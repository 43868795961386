import jwtDecode from "jwt-decode";
import { ACCESS_TOKEN_KEY, AUTH_API_URL, LMS_BASE_URL, REFRESH_TOKEN_KEY } from "../app-config";
import { getCookie } from "./cookie";

export interface AuthenticatedUser {
  userid: string;
  username: string;
  role: string;
  tokenexpires: string;
  Realm: string;
  forceSSL: string;
  LogoutUrl: string;
  permissions: string;
  authpermissions: string;
  requestinguserid?: string;
  nbf: number;
  exp: number;
  iat: number;
  iss: string;
  aud: string;
}

export function getAccessToken(): string | undefined {
  return getCookie(ACCESS_TOKEN_KEY);
}

export function getRefreshToken(): string | undefined {
  return getCookie(REFRESH_TOKEN_KEY);
}

export async function getAccessTokenAsync(): Promise<string | undefined> {
  const token = getAccessToken();

  if (isValidToken(token)) {
    return token;
  }

  const refreshToken = getRefreshToken();

  if (refreshToken) {
    try {
      await fetch(`${AUTH_API_URL}/Refresh`, {
        method: "POST",
        credentials: "include"
      })

      return getAccessToken();
    } catch(error) {
      console.warn("Failed to refresh tokens", error);
      return undefined;
    }
  }

  return undefined;
}

export function isValidToken(inputToken?: string): boolean {
  const token = inputToken ?? getAccessToken();

  if (!token) {
    return false;
  }

  const decoded = decodeToken(token);

  if (!decoded) {
    return false;
  }

  const now = Date.now().valueOf() / 1000;
  return now < decoded.exp;
}

export function isDecodedTokenValid(decodedToken: AuthenticatedUser): boolean {
  const now = Date.now().valueOf() / 1000;
  return now < decodedToken.exp;
}

export function decodeToken(jwt: string): AuthenticatedUser | null {
  try {
    return jwtDecode<AuthenticatedUser>(jwt);
  } catch (err) {
    console.error(`Invalid token`, err);
    return null;
  }
}

export function getLmsBaseUrl(): string | undefined {
  return getCookie(LMS_BASE_URL);
}
