export const enum DocTitle {
  Catalog = "Catalog",
  Course = "Course Hub",
  None = ""
}

export function generateDocTitle(pathname: string) {
  let titlePrefix = DocTitle.None;
  let basename = pathname.includes('/') ? pathname?.split('/')[1].toLowerCase(): "";
  
  if (basename === "catalog" || basename === "") {
    titlePrefix = DocTitle.Catalog;
  } else if (basename === "course") {
    titlePrefix = DocTitle.Course;
  }
  
  return titlePrefix === DocTitle.None
    ? "Imagine Edgenuity for Educators"
    : `${titlePrefix} - Imagine Edgenuity for Educators`;

}

export function preventReloadOnAbsoluteUrl(state: {}, url: string) {
  try {
    history.pushState(state, "", url);
  } catch {
    window.location.href = url;
  }
};
