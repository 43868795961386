import Cookies from "js-cookie";
import { LAST_ACCESS_TIME, LMS_BASE_URL, FIFTEEN_MINUTES_IN_MILLISECONDS } from "./app-config";
import { getAccessToken } from "./utils/auth";

export function getLastAccessTime() {
  let lastAccessTime = localStorage.getItem(LAST_ACCESS_TIME);
  let parsed = parseInt(lastAccessTime || "");
  return (isNaN(parsed) || parsed <= 0) ? 0 : parsed;
}

function getBaseUrl(): string | undefined {
  let baseUrl = Cookies.get(LMS_BASE_URL);
  if(baseUrl) {
    return baseUrl;
  }

  // If no cookie is found check the window.APP_ENV
  if (!baseUrl) {
    let lmsBaseUrl = window.APP_ENV[LMS_BASE_URL.toUpperCase()];
    if (lmsBaseUrl) {
      baseUrl = lmsBaseUrl;
    }
  }

  return baseUrl;
}

export async function updateActiveSession(): Promise<boolean> {
  if (Date.now().valueOf() - getLastAccessTime() < FIFTEEN_MINUTES_IN_MILLISECONDS) {
    let token = await getAccessToken();
    let headers = { "Authorization": `Bearer ${token}` };
    let json = JSON.stringify(headers);
    let blob = new Blob([json], { type: 'application/json' });
    let lmsBaseUrl = getBaseUrl();
    let url = `https://${lmsBaseUrl}/lmsapi/api/SessionLog/UpdateActiveSession`;
    navigator.sendBeacon(url, blob);
  }
  return true;
}

export async function setupManageActiveSession() {
  let lastAccessTime = getLastAccessTime();
  localStorage.setItem(LAST_ACCESS_TIME, Date.now().valueOf().toString());
  if (lastAccessTime === 0 || Date.now().valueOf() - lastAccessTime > FIFTEEN_MINUTES_IN_MILLISECONDS) {
    await updateActiveSession();
  }
  setInterval(updateActiveSession, FIFTEEN_MINUTES_IN_MILLISECONDS);
}

