import Cookies from "js-cookie";

export const getCookie = (cookieName: string) => {
    return Cookies.get(cookieName);
}

export const deleteCookie = (cookieName: string) => {
    Cookies.remove(cookieName);
    Cookies.remove(cookieName, { path: '', domain: '.edgenuity.com' })
    Cookies.remove(cookieName, { path: '/', domain: '.edgenuity.com' })
    Cookies.remove(cookieName, { path: '/', domain: 'educator.cwngi.edgenuity.com' })
    Cookies.remove(cookieName, { path: '/', domain: 'educator.ex.edgenuity.com' })
}

export const setCookie = (cookieName: string, cookieValue: string) => {
    Cookies.set(cookieName, cookieValue, { domain: ".edgenuity.com" });
}