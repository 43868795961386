declare global {
  interface Window {
    APP_ENV: {
      [key: string]: string;
    };
    HEADER_ENV: {
      [key: string]: string;
    };
  }
}

export const EDGE_TOKEN_KEY = "EdgeToken";
export const ACCESS_TOKEN_KEY = "access_token";
export const REFRESH_TOKEN_KEY = "refresh_token";
export const LMS_BASE_URL = "lms_base_url";
export const LAST_ACCESS_TIME = "last_access";

const appEnv = window.APP_ENV;

export const AUTH_API_URL = appEnv.AUTH_API_URL;

export const COURSE_UI_URL = appEnv.COURSE_UI_URL;
export const SECTION_UI_URL = appEnv.SECTION_UI_URL;
export const ENROLLMENT_UI_URL = appEnv.ENROLLMENT_UI_URL;
export const GRADING_UI_URL = appEnv.GRADING_UI_URL;
export const MARKETING_UI_URL = appEnv.MARKETING_UI_URL;
export const CATALOG_UI_URL = appEnv.CATALOG_UI_URL;
export const LICENSING_UI_URL = appEnv.LICENSING_UI_URL;
export const ATTENDANCE_LOG_UI_URL = appEnv.ATTENDANCE_LOG_UI_URL;
export const AUTHORING_UI_URL = appEnv.AUTHORING_UI_URL;
export const SESSION_LOG_UI_URL = appEnv.SESSION_LOG_UI_URL;
export const DEFAULTCOURSE_OPTIONS_UI_URL = appEnv.DEFAULTCOURSE_OPTIONS_UI_URL;
export const BULKFILE_UI_URL = appEnv.BULKFILE_UI_URL;
export const IMPERSONATION_BANNER_UI_URL = appEnv.IMPERSONATION_BANNER_UI_URL;
export const FIFTEEN_MINUTES_IN_MILLISECONDS = 15 * 60 * 1000;
