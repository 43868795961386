// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
}

#main-content {
    background-color: #fafbff;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    height: 100%;
}

#single-spa-application\\:header {
    flex-shrink: 0;
}

.cwng-applications {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    overflow: auto;
}

.cwng-application:not(:empty) {
    width: 100%;
    min-height: 100%;
    position: relative;
}

#single-spa-application\\:catalog > .MuiBox-root {
    min-height: unset;
    position: relative;
}

#single-spa-application\\:catalog, #single-spa-application\\:marketing {
    min-height: unset;  
    height: auto;
}

#single-spa-application\\:footer {
    flex-grow: 0;
    min-height: 2.5rem;
    padding: 0.5rem;
    text-align: center;
    overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./static/css/site.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,UAAU;IACV,SAAS;AACb;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,iBAAiB;IACjB,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,sBAAsB;IACtB,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":["html, body {\n    height: 100%;\n    width: 100%;\n    padding: 0;\n    margin: 0;\n}\n\n#main-content {\n    background-color: #fafbff;\n    display: flex;\n    flex-wrap: nowrap;\n    flex-direction: column;\n    height: 100%;\n}\n\n#single-spa-application\\:header {\n    flex-shrink: 0;\n}\n\n.cwng-applications {\n    display: flex;\n    flex-grow: 1;\n    flex-direction: column;\n    overflow: auto;\n}\n\n.cwng-application:not(:empty) {\n    width: 100%;\n    min-height: 100%;\n    position: relative;\n}\n\n#single-spa-application\\:catalog > .MuiBox-root {\n    min-height: unset;\n    position: relative;\n}\n\n#single-spa-application\\:catalog, #single-spa-application\\:marketing {\n    min-height: unset;  \n    height: auto;\n}\n\n#single-spa-application\\:footer {\n    flex-grow: 0;\n    min-height: 2.5rem;\n    padding: 0.5rem;\n    text-align: center;\n    overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
