export type CleanupFn = () => void;
export type ScriptStatus = "loading" | "ready" | "error";
export type StatusChangeFn = (status: ScriptStatus) => void;

export function loadScript(src: string): Promise<ScriptStatus>;
export function loadScript(
  src: string,
  onStatusChange: StatusChangeFn
): CleanupFn;

export function loadScript(src: string, onStatusChange?: StatusChangeFn) {
  if (typeof onStatusChange === "function") {
    return _loadScript(src, onStatusChange);
  }

  return _loadScriptPromise(src);
}

function _loadScript(src: string, onStatusChange: StatusChangeFn): CleanupFn {
  let script = document.querySelector<HTMLScriptElement>(
    `script[src="${src}"]`
  );

  if (!script) {
    script = document.createElement("script");
    script.src = src;
    script.type = "text/javascript";
    script.async = true;
    script.setAttribute("data-status", "loading");

    document.body.appendChild(script);

    const setAttributeFromEvent = (event: Event) => {
      script!.setAttribute(
        "data-status",
        event.type === "load" ? "ready" : "error"
      );
    };

    script.addEventListener("load", setAttributeFromEvent);
    script.addEventListener("error", setAttributeFromEvent);
  } else {
    const attr = script.getAttribute("data-status") as ScriptStatus;
    onStatusChange(attr || "ready");
  }

  const setStateFromEvent = (event: Event) => {
    onStatusChange(event.type === "load" ? "ready" : "error");
  };

  script.addEventListener("load", setStateFromEvent);
  script.addEventListener("error", setStateFromEvent);

  return () => {
    if (script) {
      script.removeEventListener("load", setStateFromEvent);
      script.removeEventListener("error", setStateFromEvent);
    }
  };
}

async function _loadScriptPromise(src: string): Promise<ScriptStatus> {
  return new Promise<ScriptStatus>((resolve, reject) =>
    loadScript(src, (scriptStatus) => {
      if (scriptStatus === "ready") {
        resolve(scriptStatus);
      } else {
        reject(new Error(`Error when trying to load ${src}`));
      }
    })
  );
}
